import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';
import BackgroundImage from 'gatsby-background-image';

import Layout from '../components/layout';
import SEO from '../components/util/seo';
import IndicatorGrid from '../components/page/indicatorGrid';

import DotGraphic from '../images/dot-graphic.svg';
import '../styles/index.css';

export const query = graphql`
  {
    hope {
      allPageTexts(where: { page: Home }, orderBy: "order") {
        heading
        text
      }
      allCategories(orderBy: "order") {
        heading
        text
        link
        type
      }
      allDomains(orderBy: "order") {
        name
        key
        overview
        color
        score(sortBy: score_DESC) {
          state {
            abbreviation
          }
          score
        }
      }
    }
    jumbotron: file(relativePath: { eq: "jumbotron.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <BackgroundImage
      style={{ width: '100vw', height: '60vh' }}
      className="jumbotron"
      fluid={data.jumbotron.childImageSharp.fluid}
      backgroundColor="#455a64"
    >
      <Container>
        <Row>
          <h1 style={{ color: 'white', fontSize: 60 }}>
            Measures to Advance Health and Opportunity
          </h1>
        </Row>
      </Container>
    </BackgroundImage>
    <section>
      <Row>
        <Col lg={4}>
          <DotGraphic style={{ margin: '50px  0' }} />
        </Col>
        <Col>
          <Container style={{ paddingTop: 50, paddingBottom: 50, paddingLeft: 30 }}>
            <h2>{data.hope.allPageTexts[0].heading}</h2>
            {parse(data.hope.allPageTexts[0].text)}
          </Container>
        </Col>
      </Row>
    </section>
    <section
      style={{ clear: 'both', borderBottom: '8px solid #c8e8f6', backgroundColor: '#e8f8ff' }}
    >
      <Container>
        <Row>
          <Col lg={4}>
            <h2>{data.hope.allPageTexts[1].heading}</h2>
          </Col>
          <Col>{parse(data.hope.allPageTexts[1].text)}</Col>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          {data.hope.allCategories.map(category => (
            <Col key={category.type} className="measurements" lg={3} md={6} sm={12}>
              <h3 style={{ marginTop: 0 }}>{category.heading}</h3>
              {parse(category.text)}
            </Col>
          ))}
        </Row>
      </Container>
    </section>
    <section>
      <Container>
        <Row>
          <Col md={4}>
            <h2>{data.hope.allPageTexts[2].heading}</h2>
          </Col>
          <Col>{parse(data.hope.allPageTexts[2].text)}</Col>
        </Row>
      </Container>
      <Row className="nav nav-tabs domainTabs">
        {data.hope.allDomains.map(domain => (
          <Col
            key={domain.key}
            className={`nav-item nav-link ${domain.key}`}
            onClick={() => {
              navigate(`/domain/${domain.name.replace(/(\W+)/gm, '-').toLowerCase()}`);
            }}
          >
            {domain.name}
          </Col>
        ))}
      </Row>
      <IndicatorGrid />
    </section>
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.shape({
    hope: PropTypes.shape(),
    jumbotron: PropTypes.shape(),
  }).isRequired,
};

export default IndexPage;
