import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery, Link } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './indicatorGrid.css';

const IndicatorGrid = ({ domains }) => (
  <StaticQuery
    query={graphql`
      {
        hope {
          allDomains(orderBy: "order") {
            name
            color
            indicator(orderBy: "name") {
              name
            }
          }
        }
      }
    `}
    render={data => (
      <div className="indicatorGrid">
        <Row style={{ margin: 0 }}>
          {data.hope.allDomains.map(domain => (
            <Col key={domain.name} style={{ backgroundColor: domain.color }}>
              {domains && <h4>{domain.name}</h4>}
              <ul>
                {domain.indicator.map(({ name }) => (
                  <Link key={name} to={`/indicator/${name.replace(/(\W+)/gm, '-').toLowerCase()}`}>
                    <li>{name}</li>
                  </Link>
                ))}
              </ul>
            </Col>
          ))}
        </Row>
      </div>
    )}
  />
);

IndicatorGrid.propTypes = {
  domains: PropTypes.bool,
};

IndicatorGrid.defaultProps = {
  domains: false,
};

export default IndicatorGrid;
